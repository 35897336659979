import { CharacterService } from "@moe/priv/model/character";
import { z } from "zod";

const { rankingSchema, periodSchema, orderSchema } = CharacterService;

export const PAGE_SIZE = 28;

export const searchSchema = z.object({
  ranking: rankingSchema.optional(),
  searchText: z.string().optional(),
  tags: z.array(z.string()).optional(),
  page: z.number().min(1).optional().catch(1),
  pageSize: z.number().min(1).optional(),
  order: orderSchema.optional(),
  period: periodSchema.optional(),
  nsfwOK: z.boolean().optional()
});
